.chip {
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid rgba(0,0,0,.125);
    background-color: white;
    padding: 5px 8px;
}

.chip.active {
    color: limegreen;
    background-color: #90ee9020;
}