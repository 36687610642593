.main-container {
    max-width: 800px;
    margin-top: 20px;
}

.bg-pbstep {
    background-color: #90ee90;
}

.continue-block {
    display: flex;
    align-items: center;
}

.continue-block p {
    color: red;
    font-size: 1rem;
    margin-bottom: 0;
    margin-left: 25px;
}

.range-slider-lpp-range {
    width: 100%;
    display: flex;
}
.range-slider-lpp {
    margin-top: 50px;
    margin-bottom: 50px;
}
.range-slider-lpp span.helpers {
    font-weight: bold;
    font-size: 1.1rem;
}

#iframe-calendly div.WrdpezlzjKu1CoRihaXS Rb2e9IwsOFiKlSQIX7YA y2BHUjkNYLaMI5GB_dVt {display: none;}

@media screen and (max-width: 576px) {
    .button-button, .datepicker-ctn, .input-text {
        width: 100% !important;
    }
    .continue-block {
        flex-direction: column;
        align-items: flex-start;
    }
    .continue-block p {
        margin-left: 0;
    }
    .range-slider-lpp span.helpers {
        display: none;
    }
    .container-step {
        padding-top: 10px;
    }
}