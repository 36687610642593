.button-button {
    padding: 20px 35px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 19%);
    border-radius: 15px;
    display: block;
    border: none;
    font-size: 1.3rem;
    background-image: linear-gradient(to right, #77A1D3 0%, #79CBCA 51%, #77A1D3 100%);
}

.button-button:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.button-button:not(:disabled):hover {
    background-position: right center;
}

.button-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.button-inline {
    outline: none;
    border: none;
    background-color: transparent;
    color: #00617a;
}

.button-outline {
    background-color: transparent;
    padding: 0.375rem 0.75rem;
    color: #00617a;
    border: 1px solid #00617a;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.button-outline:hover {
    color: white;
    background-color: #00617a;
}

@media screen and (max-width: 576px) {
    .button-button {
        width: 100%;
    }
}