.PhoneInputCountry {
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-right: none;
    border-radius: 10px;
    margin-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-left: 15px;
    padding-right: 8px;
}

@media screen and (max-width: 576px) {
    .PhoneInput {
        width: 100%;
    }
}