header {
    height: 85px;
    background-color:  #101434;
    display: flex;
    align-items: center;
}

.logo {
    max-height: 56px;
}

.green-phone {
    color: #5cb846;
}

.phone-number {
    text-decoration: none;
    font-size: 20px;
    margin-left: 5px;
}

.phone-hours {
    color: #a7b1c2;
}