.input-text {
    border: 1px solid #acbac8;
    width: 225px;
    padding: 13px 29px;
    font-size: 1.2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(19 172 247 / 10%), 0 6px 20px 0 rgb(19 172 247 / 10%);
}

.input-text:focus {
    border: 2px solid limegreen !important;
    background-color: #F1FAFC;
}

.input-text.valid {
    color: limegreen !important;
    border: 2px solid limegreen !important;
}

.input-text.error:not(:focus-within) {
    border: 2px solid red !important;
    box-shadow: 0 4px 8px 0 rgb(255 53 53 / 10%), 0 6px 20px 0 rgb(255 53 53 / 10%);
    background-color: rgba(255,231,231,0.5);
    color: red;
} 

.input-text.error:not(:focus-within) input {
    color: red;
}

.input-err {
    color: red;
}