.custom-range {
    -webkit-appearance: none;
    appearance: none;
    width: 100%; /* Full-width */
    height: 15px;
    border-radius: 5px; 
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    z-index: 10;
}

.range__ticks {
    position: absolute;
}

/* Mouse-over effects */
/* .custom-range:hover {
    opacity: 1;
  } */
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.custom-range::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    border-radius: 50%; 
    background: #00617a; /* Green background */
    cursor: pointer; /* Cursor on hover */
    box-shadow: 0px 0px 5px 2px rgb(19 172 247 / 50%);
}
  
.custom-range::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    border-radius: 50%; 
    background: #00617a; /* Green background */
    cursor: pointer; /* Cursor on hover */
}

