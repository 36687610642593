.card-tile {
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 12px;
    cursor: pointer;
    height: 200px;
    width: 190px;
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #acbac8;
    color: #00617a;
    box-shadow: 0 4px 8px 0 rgb(19 172 247 / 10%), 0 6px 20px 0 rgb(19 172 247 / 10%);
    transition: border-width 0.1s linear;
    justify-content: space-evenly;
    font-size: 1.2rem;
}

.card-tile-text {
    text-align: center;
}

.card-tile:hover {
    border: 1px solid limegreen;
}

.card-tile:hover .card-tile-text,
.card-tile:hover svg {
    color: limegreen;
}

.card-tile.active {
    border: 2px solid limegreen !important;
    color: limegreen !important;
    background-color: #F1FAFC;
}

.card-tile:hover .tile-radio {
    border: 1px solid limegreen !important;
}

.card-tile .tile-radio {
  display: flex;
  justify-content: center;
  border: 1px solid #acbac8;
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
    
}

.card-tile.active .tile-radio {
    background-color: limegreen;
    border-color: limegreen;
}

.card-tile.active .tile-radio:before {
    content: "\2713";
    color: white;
    font-size: 16px;
    line-height: 22px;
}