.card-info {
    display: flex;
    background-color: #ffffff;
    padding: 10px;
    display: flex;
    border-radius: 15px;
    align-items: center;
    border: 1px solid rgba(0,0,0,.125);
    margin-bottom: 20px;
}

.card-info  img {
    height: 75px;
    border-radius: 45px;
}

.card-info .div-text {
    flex: 1;
}

.card-info .div-text>p {
    margin-left: 20px;
    margin-bottom: 0;    
    font-size: 1.3rem;
    color: #253439;
}