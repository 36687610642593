.tile-ctn {
    padding: 13px 29px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 10px;

    display: flex;
    align-items: center;

    background-color: #ffffff;
    width: 100%;
    border: 1px solid #acbac8;
    color: #00617a;
    box-shadow: 0 4px 8px 0 rgb(19 172 247 / 10%), 0 6px 20px 0 rgb(19 172 247 / 10%);

    transition: border-width 0.1s linear;
    height: 72px;
}

.tile-group {
    display: flex;
    align-items: center;
    height: 40px;
}

.tile-ctn:hover {
    border: 1px solid limegreen !important;
}

.tile-ctn:hover .tile-radio {
    border: 1px solid limegreen !important;
}

.tile-ctn .tile-radio {
  display: flex;
  justify-content: center;
  border: 1px solid #acbac8;
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 50%;
  margin-right: 15px;
    
}

.tile-ctn.active {
    border: 2px solid limegreen !important;
    color: limegreen !important;
    background-color: #F1FAFC;
}

.tile-ctn.active .tile-radio {
    background-color: limegreen;
    border-color: limegreen;
}

.tile-ctn.active .tile-radio:before {
    content: "\2713";
    color: white;
    font-size: 16px;
    line-height: 22px;
}
